import React, {Component} from 'react'

class Landing extends Component {
	constructor (props) {
		super(props);
		this.scroll = React.createRef();
		this.state = {
			landingImage:'/images/landings/landing.jpg',
			mobileLanding:'/images/landings/landing.jpg'
		};
	}

	componentDidMount () {

	}

	componentWillUnmount () {
		window.removeEventListener('resize', this.resize, false)
	}

	render () {
		return (
			<div className="landing-wrapper">
				<div className="landing-image-wrapper">
					<div className="landing-image">
						<img className="landing-image-picture" src={"/images/landings/landing.jpg"} alt="meeting"/>
						<img className="landing-image-shape-1" src={'/images/landings/half-square.svg '} alt="meeting"/>
						<img className="landing-image-shape-2" src={'/images/landings/half-square-reverse.svg'}
							 alt="meeting"/>
						<img src="/images/sections/square.svg" className="square" alt="half-square"/>
						<div className="landing-image-text">
							<h1><span>ESP</span> <span style={{color:"white"}}>Consultancy</span></h1>
							<h3>CONNECTING THE DOTS BY BUILDING A SOLID BRIDGE BETWEEN COMPANIES AND CANDIDATES</h3>
						</div>
					</div>
					<img
						src={this.state.mobileLanding}
						alt="meeting" className="landing-image-mobile"
					/>
					<div className="landing-background">
						<img className="colored-rectangle"
							 src={'/images/landings/colored-rectangle.svg'}
							 alt="meeting"
						/>
						<img className="striped-square"
							 src={'/images/landings/striped-square.png'}
							 alt="meeting"
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default Landing
