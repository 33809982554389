import React from "react";
import { translate } from "react-multi-lang";
import { generalPagesImagePath } from "../../App";
import { NavLink } from "react-router-dom";

class PageItem extends React.PureComponent {
    render() {
        const { page, name, paragraphs, t, noButton, customUrl, noReadMore, externalUrl } = this.props;
        return (

            <div id={this.props.id} className="page-item">
                <div className="title">
                    <img
                        className="work-box"
                        src={`${generalPagesImagePath}${page}/${name}.svg`}
                        alt={""}
                    />
                        <h2>{t(`${page}.${name}.title`)}</h2>

                </div>
                <div className="text">
                    {paragraphs === undefined ? (
                        <React.Fragment>
                            <p>{t(`${page}.${name}.preview`)}</p>
	                        {!noReadMore &&
                            <NavLink exact to={`/read_more/${page}/${name}`}>
                                <div className="read-more">
                                    {t("page_item.read_more")}
                                </div>
                            </NavLink>
	                        }
                        </React.Fragment>
                    ) : (
                        this.renderParagraphs()
                    )}
                </div>
                {!noButton && (
                    <NavLink
                        exact
                        to={customUrl ? customUrl : `contact/${page}/${name}`}
                    >
                        <div className="general-button">
                            <p>
                                {customUrl
                                    ? t(`${page}.${name}.button`)
                                    : t("page_item.contact")}
                            </p>
                        </div>
                    </NavLink>
                )}

                {externalUrl &&  (
                    <a href={externalUrl} target="_blank" rel="noopener noreferrer">
                        <div className="general-button">
                            <p>
                                {externalUrl ? t(`${page}.${name}.button`) : t("page_item.contact")}
                            </p>
                        </div>
                    </a>
                )}

            </div>
        );
    }

    // renderParagraphs() {
    //     const { t, paragraphs, page, name } = this.props;
    //     const pars = [];
    //     for (let i = 0; i < paragraphs; i++) {
    //         pars.push(
    //             <React.Fragment key={i}>
    //                 <p>{t(`${page}.${name}.paragraph_${i + 1}`)}</p>
    //                 {i + 1 < paragraphs && <br />}
    //             </React.Fragment>
    //         );
    //     }
    //     return pars;
    // }

    renderParagraphs = () => {

        const breakWord = "AlineaBreak";
        const { t, page, name } = this.props;
            const pars = [];
        const Paragraphs = t(`${page}.${name}.text`);
        const Array = Paragraphs.split(breakWord);
        for(let i =0; i < Array.length; i++){
            pars.push(
                <React.Fragment>
                    <p>{Array[i]}</p>
                    <br/>
                </React.Fragment>
            )
        }
            return pars;
    }
}

export default translate(PageItem);
