import React from "react";

const logoArray = [
    {img: "/images/banner/abn.svg", name: "ABN AMBRO", id: "abn-ambro"},
    {img: "/images/banner/allego.svg", name: "Allego", id: "allego"},
    {img: "/images/banner/apg.svg", name: "APG", id: "apg"},
    // {img: "/images/banner/asml.svg", name: "ASML", id: "asml"},
    {img: "/images/banner/belasting.svg", name: "Belastingdienst", id: "belastingdienst"},
    {img: "/images/banner/damco.svg", name: "Damco", id: "damco"},
    {img: "/images/banner/glencore.svg", name: "Glencore", id: "glencore"},
    {img: "/images/banner/ing.svg", name: "ING", id: "ing"},
    {img: "/images/banner/keylane.svg", name: "Keylane", id: "keylane"},
    // {img: "/images/banner/libertyglobal.svg", name: "Liberty Global", id: "liberty-global"},
    // {img: "/images/banner/mercedes.svg", name: "Mercedes", id: "mercedes"},
    {img: "/images/banner/ministerie-eco.svg", name: "Ministerie van Economische Zaken", id: "ministerie-eco"},
    {img: "/images/banner/ministerie-veiligheid.svg", name: "Ministerie van Veiligheid", id: "ministerie-veiligheid"},
    {
        img: "/images/banner/rijkswaterstaat.svg",
        name: "Rijkswaterstaat Ministerie van Infrastructuur en Waterstaat",
        id: "ministerie-rijkswaterstaat"
    },
    {img: "/images/banner/mn.svg", name: "MN", id: "mn"},
    {img: "/images/banner/nationale.svg", name: "Nationale-Nederlanden", id: "nationale-nederlanden"},
    // {img: "/images/banner/ns.svg", name: "Nationale Spoorwegen", id: "ns"},
    {img: "/images/banner/nuon.svg", name: "Nuon", id: "nuon"},
    {img: "/images/banner/nxp.svg", name: "NXP Semiconductors", id: "nxp"},
    // {img: "/images/banner/philips.svg", name: "Phillips", id: "phillips"},
    {img: "/images/banner/prorail.svg", name: "ProRail", id: "pro-rail"},
    {img: "/images/banner/rabobank.svg", name: "Rabobank", id: "rabobank"},
    // {img: "/images/banner/steegman.svg", name: "Steegman", id: "steegman"},
    {img: "/images/banner/Stolt.svg", name: "Stolt-Nielsen", id: "stolt-nielsen"},
    {img: "/images/banner/tennet.svg", name: "Tennet", id: "tennet"},
    {img: "/images/banner/vattenfal.svg", name: "Vattenfal", id: "vattenfal"},
    // {img: "/images/banner/vodafone.svg", name: "Vodafone", id: "vodafone"},
    // {img: "/images/banner/ziggo.svg", name: "Ziggo", id: "ziggo"},
];

const Banner = (props) => {
    return (
        <div className="banner-wrapper" id={props.id}>
            <div className="banner-amar">
            {logoArray.map((data, index) => {
                return (
                    <div key={index} className="banner-item-amar">
                        <img src={data.img} alt={data.name} id={data.id}/>
                    </div>
                );
            })}
            </div>
            {/*<div className="banner-wrapper-wrapper">*/}
            {/*    <div className="banner">*/}
            {/*        {logoArray.map((data, index) => {*/}
            {/*            return (*/}
            {/*                <div key={index} className="banner-item">*/}
            {/*                    <img src={data.img} alt={data.name} id={data.id}/>*/}
            {/*                </div>*/}
            {/*            );*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>
    );
};
export default Banner;
