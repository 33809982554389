import React, {Component} from 'react';
import {translate} from "react-multi-lang";

class WideText extends Component {

    renderParagraphs = () => {
        const breakWord = "AlineaBreak";
        const { t,  } = this.props;
        const pars = [];
        const Paragraphs = t(`about.text2`);
        console.log(Paragraphs);
        //If this is empty so for the dutch tranlsation
        if(Paragraphs === "about.text2"){
            return null
        }
        else {
            const Array = Paragraphs.split(breakWord);
            for(let i =0; i < Array.length; i++){
                pars.push(
                    <React.Fragment>
                        <p>{Array[i]}</p>
                        <br/>
                    </React.Fragment>
                )
            }
            return pars;
        }
    };


    render() {
        const {t} =  this.props;
        return (
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                <h3>{t("home.team.title")}</h3>
                <div className="event-read-more-text">{this.renderParagraphs()}</div>
            </div>
        );
    }
}

export default translate(WideText);

