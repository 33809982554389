import React, { Component } from "react";
import GenericPage from "../pages/GenericPage";
import PageItem from "../pages/PageItem";
import { translate } from "react-multi-lang";

class Freelance extends Component {
    render() {
        const pageName = "freelance";

        return (
            <GenericPage page={pageName}>
                <PageItem page={pageName} id="leveranciers" name={"leveranciers"} noReadMore />
                <PageItem page={pageName} id="freelancers" name={"freelancers"} />
                <PageItem page={pageName} id="vaste_banen" name={"vaste_banen"} />
                <PageItem page={pageName} externalUrl="http://www.espjobportal.nl/"
                          noReadMore id="opdrachten" name={"opdrachten"} noButton />
                <PageItem page={pageName} id="inschrijven" name={"inschrijven"} noReadMore/>
                <PageItem page={pageName} id="tips" name={"tips"} noButton />
                <PageItem page={pageName} id="werkwijze" name={"werkwijze"} noButton />
                <PageItem page={pageName} id="freelancer_worden" name={"freelancer_worden"} />
            </GenericPage>
        );
    }
}

export default translate(Freelance);
