import React from "react";
import { Form, Input, TextArea } from "semantic-ui-react";

export default class InputField extends React.PureComponent {
    render() {
        const { label, handleChange, isBigField, isMandatory } = this.props;
        return isBigField ? (
            <Form.TextArea
                className="big-field"
                control={TextArea}
                label={`${label}${isMandatory ? `*` : ``}`}
                onChange={(_, { value }) => handleChange(label, value)}
            />
        ) : (
            <Form.Field
                control={Input}
                label={`${label}${isMandatory ? `*` : ``}`}
                onChange={(_, { value }) => handleChange(label, value)}

            />
        );
    }
}
