import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import Language from '../Language'
import {getLanguage, translate} from 'react-multi-lang'
import {NavHashLink} from 'react-router-hash-link'

class Header extends Component {
    static selectPreferredLanguage(language) {
        Language.selectPreferredLanguage(language);
        window.location.reload()
    }

    constructor(props) {
        super(props);
        this.dutch = React.createRef();
        this.english = React.createRef();
        this.header = React.createRef();
        this.hiddenContent = React.createRef();

        this.state = {
            menuOpen: false,
            language: getLanguage(),
            flag: '/images/header/dutch.svg',
            flag_Alt: 'Dutch-flag',
            header: '',
        }
    }

    menuClick = () => {
        this.setState({menuOpen: false}, () => {
            this.disableScroll()
        })
    };

    handleLanguage = (language) => {
        switch (language.current.id) {
            case 'dutch-flag':
                this.setState({flag: language.current.src});
                this.setState({flag_Alt: language.current.alt});
                Header.selectPreferredLanguage('nl');
                break;
            case 'english-flag':
                this.setState({flag: language.current.src});
                this.setState({flag_Alt: language.current.alt});
                Header.selectPreferredLanguage('en');
                break;
            default:
                Header.selectPreferredLanguage('nl')
        }
    };

    hamburgerClick = () => {
        this.setState({menuOpen: !this.state.menuOpen}, this.disableScroll)
    };

    // Disables scroll when mobile menu is open
    disableScroll = () => {
        document.body.style.overflow = this.state.menuOpen ? 'hidden' : 'visible'
    };

    sticky = () => {
        let sticky = this.header.current.getBoundingClientRect().top;
        if (window.pageYOffset > sticky) {
            if (this.state.header !== 'sticky') {
                this.setState({header: 'sticky'})
            }
        } else {
            this.setState({header: ''})
        }
    };

    componentDidMount() {
        if (getLanguage().toString() === 'nl') {
            this.setState({flag: '/images/header/dutch.svg'})
        } else if (getLanguage().toString() === 'en') {
            this.setState({flag: '/images/header/english.svg'})
        }
        window.addEventListener('scroll', this.sticky, false)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.sticky, false)
    }

    render() {
        const {t} = this.props;
        return (
            <div className={`nav-wrapper ${this.state.header}`}
                 ref={this.header}>
                <div className="nav-logo-wrapper">
                    <NavLink to="/">
                        <img className="nav-logo" src={'/images/logo/logo.svg'}
                             alt="logo"/>
                    </NavLink>
                </div>

                <div className="nav-menu-wrapper">
                    <div ref={this.hiddenContent} className="nav-menu-content pc-nav">
                        <li id="home-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" exact to="/"><h4>{t(`navbar.home`)}</h4></NavLink>
                            <div className="hidden-nav-content" id="home-content">
                                <NavHashLink smooth to="/#over_esp">{t(`home.esp.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth to="/#werk">{t(`home.workcards.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth to="/#phares">{t(`home.phares.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/#clienten">{t(`home.clients.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth to="/contact/general_contact/info">CONTACT</NavHashLink>
                            </div>
                        </li>

                        <li id="freelance-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" exact to="/freelance"><h4>{t(
                                `navbar.freelancers`)}</h4></NavLink>
                            <div className="hidden-nav-content" id="freelance-content">
                                <NavHashLink smooth
                                             to="/freelance#leveranciers">{t(`freelance.leveranciers.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/freelance#freelancers">{t(`freelance.freelancers.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/freelance#vaste_banen">{t(`freelance.vaste_banen.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/freelance#opdrachten">{t(`freelance.opdrachten.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/freelance#inschrijven">{t(`freelance.inschrijven.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/freelance#tips">{t(`freelance.tips.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/freelance#werkwijze">{t(`freelance.werkwijze.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth to="/freelance#freelancer_worden">{t(
                                    `freelance.freelancer_worden.title`).toUpperCase()}</NavHashLink>
                            </div>
                        </li>

                        <li id="diensten-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" exact to="/services"><h4>{t(`navbar.diensten`)}</h4>
                            </NavLink>
                            <div className="hidden-nav-content" id="diensten-content">
                                <NavHashLink smooth
                                             to="/services#concept">{t(`diensten.concept.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/services#freelance">{t(`diensten.freelance.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/services#expat">{t(`diensten.expat.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/services#payroll">{t(`diensten.payroll.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/services#juridisch">{t(`diensten.juridisch.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth
                                             to="/services#opleidingen">{t(`diensten.opleidingen.title`).toUpperCase()}</NavHashLink>
                            </div>
                        </li>

                        <li id="employers-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" exact to="/employers">
                                <h4>{t(`navbar.opdrachtgevers`).toUpperCase()}</h4></NavLink>
                            <div className="hidden-nav-content" id="employers-content">
                                <NavHashLink smooth to="/employers#vast_personeel">{t(
                                    `opdrachtgever.vast_personeel.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth to="/employers#tijdelijk_personeel">{t(
                                    `opdrachtgever.tijdelijk_personeel.title`).toUpperCase()}</NavHashLink>
                                <NavHashLink smooth to="/employers#bechikbare_profielen">{t(
                                    `opdrachtgever.beschikbare_profielen.title`).toUpperCase()}</NavHashLink>
                            </div>
                        </li>

                        <li id="partners-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" to="/partners">
                                <h4>{t(`navbar.partners`).toUpperCase()}</h4></NavLink>
                            <div className="hidden-nav-content" id="partners-content">
                                <NavHashLink smooth
                                             to="/partners#waarom_esp">{t(`partners.waarom.title`).toUpperCase()}</NavHashLink>
                                {/*<NavHashLink smooth*/}
                                             {/*to="/partners#werkwijze">{t(`partners.werkwijze.title`).toUpperCase()}</NavHashLink>*/}
                            </div>
                        </li>

                        <li id="tech-community-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" to="/testimonials">
                                <h4>{t(`navbar.testimonial`).toUpperCase()}</h4></NavLink>
                            {/*<div className="hidden-nav-content" id="tech-community-content">*/}
                                {/*<NavHashLink smooth to="/tech-community#communities">{t(*/}
                                    {/*`tech_community.communities.title`).toUpperCase()}</NavHashLink>*/}
                                {/*<NavHashLink smooth*/}
                                             {/*to="/tech-community#automated">{t(`tech_community.automated.title`).toUpperCase()}</NavHashLink>*/}
                                {/*<NavHashLink smooth*/}
                                             {/*to="/tech-community#java">{t(`tech_community.java.title`).toUpperCase()}</NavHashLink>*/}
                                {/*<NavHashLink smooth*/}
                                             {/*to="/tech-community#bi">{t(`tech_community.bi.title`).toUpperCase()}</NavHashLink>*/}
                                {/*<NavHashLink smooth*/}
                                             {/*to="/tech-community#infra">{t(`tech_community.infra.title`).toUpperCase()}</NavHashLink>*/}
                                {/*<NavHashLink smooth*/}
                                             {/*to="/tech-community#evenementen">{t(`tech_community.events.title`).toUpperCase()}</NavHashLink>*/}
                            {/*</div>*/}
                        </li>
                    </div>

                    <div className="nav-menu-content mobile-nav" style={{height: this.state.menuOpen ? '100%' : 0}}>
                        <li onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" exact to="/"><h4>{t(`navbar.home`).toUpperCase()}</h4>
                            </NavLink>
                        </li>

                        <li id="freelance-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" exact to="/freelance">
                                <h4>{t(`navbar.freelancers`).toUpperCase()}</h4></NavLink>
                        </li>

                        <li id="diensten-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" exact to="/services">
                                <h4>{t(`navbar.diensten`).toUpperCase()}</h4></NavLink>
                        </li>

                        <li id="employers-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" exact to="/employers">
                                <h4>{t(`navbar.opdrachtgevers`).toUpperCase()}</h4></NavLink>
                        </li>

                        <li id="partners-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" to="/partners">
                                <h4>{t(`navbar.partners`).toUpperCase()}</h4></NavLink>
                        </li>

                        <li id="tech-community-nav" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" to="/testimonials">
                                <h4>{t(`navbar.testimonial`).toUpperCase()}</h4></NavLink>
                        </li>

                        <li id="contact" onClick={this.menuClick}>
                            <NavLink activeClassName="active-nav" to="/contact/general_contact/info"><h4>{t(
                                `navbar.contact`).toUpperCase()}</h4></NavLink>
                        </li>

                    </div>

                    <div className="nav-languages-wrapper">
                        <div className="nav-flag-div"><img src={this.state.flag}
                                                           alt={this.state.flag_Alt}/></div>
                        <div className="nav-languages-content">
                            <div id="dutch" onClick={() => this.handleLanguage(
                                this.dutch)}>
                                <img src="/images/header/dutch.svg"
                                     ref={this.dutch}
                                     id="dutch-flag" alt="Dutch-flag"
                                     className="nav-languages"/>
                            </div>
                            <div id="english"
                                 onClick={() => this.handleLanguage(
                                     this.english)}>
                                <img src="/images/header/english.svg"
                                     ref={this.english}
                                     id="english-flag"
                                     alt="English-flag"
                                     className="nav-languages"/>
                            </div>
                        </div>
                    </div>
                    <div className="nav-burger">
                            <span onClick={this.hamburgerClick}
                                  className={this.state.menuOpen
                                      ? 'hamburger--spin is-active'
                                      : 'hamburger--spin'}>
                              <span className="hamburger-box">
                                <div className="hamburger-inner"/>
                              </span>
                            </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate(Header)
