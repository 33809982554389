import React from 'react'
import {translate,} from 'react-multi-lang'
import {FaEnvelope, FaFax, FaFileContract, FaLocationArrow, FaPhone} from 'react-icons/fa'
import GridItem from './GridItem'

class Footer extends React.PureComponent {
	constructor (props) {
		super(props);

		this.state = {
			formData: {},
			success: false,
		}
	};

	render () {
		const { t } = this.props;


		const title = [
			{
				title: 'Contact',
				link: <GridItem image={<FaEnvelope/>} text="email"
								url="mailto:info@espconsultancy.nl"/>,
				link_2: <GridItem image={<FaPhone/>} text="telephone" url="tel:(0031) 0653 696291"/>,
				// link_3: <GridItem image={<FaFax/>} text="fax"/>,
				link_4: <GridItem image={<FaFileContract/>} text="kvk"/>,

				link_5: <GridItem id="arrow-position" image={<FaLocationArrow/>} text={`address`}/>,
				link_6: <GridItem id="arrow-position" image={<FaLocationArrow/>} text={`address_2`}/>,
				link_7: <GridItem id="arrow-position" image={<FaLocationArrow/>} text={`address_3`}/>,


			},
			{
				title: t(`footer.diensten`),
				link: <GridItem text="concept" noIcon url="/read_more/diensten/concept"/>,
				link_2: <GridItem text="freelancers" noIcon url="/read_more/diensten/freelance"/>,
				link_3: <GridItem text="expat" noIcon url="/read_more/diensten/expat"/>,
				link_4: <GridItem text="payroll" noIcon url="/read_more/diensten/payroll"/>,
				link_5: <GridItem text="juridisch" noIcon url="/partners"/>,
			},
			{
				title: t(`footer.about`),
				link: <GridItem text="about" noIcon url="/about"/>,
				link_2: <GridItem text="testimonials" noIcon url="/testimonials"/>,
				link_3: <GridItem text="contact" noIcon url="/contact/general_contact/info"/>,
			},
			// {
			// 	title: t(`footer.information.title`),
			// 	link: <GridItem image={<FaFacebook/>} text="facebook" url="https://www.facebook.com"/>,
			// 	link_2: <GridItem image={<FaLinkedin/>} text="linked-in"
			// 					  url="https://www.linkedin.com/in/esp-consultancy-7a3469168/"/>,
			// },
		];

		return (
			<div className="footer-container">
				<div className="footer-body">
					<div className="footer-information">
						{title.map((data, index) => {
							return <div key={index} className="footer-information-links">
								<h4 key={data.title}>{data.title}</h4>
								{data.link}
								{data.link_2}
								{data.link_3}
								{data.link_4}
								{data.link_5}
								{data.link_6}
								{data.link_7}
							</div>
						})}
					</div>

					<div className="footer-contact">
						<div className="footer-map-container">
							<iframe title="adress" className="footer-map"
									src="https://maps.google.com/maps?width=100%&height=600&hl=nl&q=Rietwerfplantsoen%2016%204273%20CH%20Hank+(ESP%20Consultancy)&ie=UTF8&t=&z=17&iwloc=B&output=embed"
									frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
									key="AIzaSyBz2P8TgIT-0rR3DecVKfgF2yDEGz3b28U"/>
						</div>

						<div className="footer-newsletter">
							<h5>{t('footer.newsletter.title')}</h5>
							<input type="text"
								   name={`name`}
								   handleChange={this._handleChange}
								   isMandatory={true}
								   placeholder={t('footer.newsletter.name')}
							/>
							<input type="text"
								   name={`email`}
								   handleChange={this._handleChange}
								   isMandatory={true}
								   placeholder={t('footer.newsletter.email')}
							/>
							<a onClick={() => this.handleSubmit()} className="subscription-button">
								{t('contact_form.submit')}
							</a>
							{this.state.success && <div>Submitted successfully</div>}

						</div>
					</div>
				</div>

				<div className="footer-end">
					<p> Design by: <a href="https://asrr.nl/" rel="noopener noreferrer" target="_blank">ASRR Tech</a>
					</p>
				</div>

			</div>
		)

	}

	_handleChange = (name, value) => {
		this.setState({ formData: Object.assign({}, this.state.formData, { [name]: value }) },
			() => console.log(this.state.formData))
	};

	handleSubmit = () => {
		let self = this;

		let formData = new FormData();

		const name = document.querySelector('input[name="name"]');
		const email = document.querySelector('input[name="email"]');

		formData.append("subject", "Nieuwsbrief aanmelding voor: " + name.value + " | " + email.value);
		formData.append("body", "name: " + name.value + "; email: " + email.value);
		formData.append("recipient", "ravi@espconsultancy.nl");


		name.value = "";
		email.value = "";

		fetch(process.env.REACT_APP_API_BASE_URL, {
			method: 'POST',
			body: formData,
		}).then(function (response) {
			if (!response.ok) {
				response.json().then(function (object) {
					console.log(object);
					console.log(object.propertyErrors);
					self.setState({ formError: object.propertyErrors })
				});
				throw new Error(response.statusText)
			}
			self.setState({ success: true });
			console.log(response)
		}).catch(error => console.log(error))
	}
}

export default translate(Footer)
