import React, { Component } from "react";
import GenericPage from "../pages/GenericPage";
import PageItem from "../pages/PageItem";
import { translate } from "react-multi-lang";
import nl from "../../images/diensten_nl.jpg"
import en from "../../images/diensten_en.jpg"

class Diensten extends Component {
    render() {
        const pageName = "diensten";


        return (
            <GenericPage specialImage={{en:en , nl: nl}} page={pageName}>
                <PageItem page={pageName} id="freelance" name={"freelance"} />
                <PageItem page={pageName} id="concept" name={"concept"} />
                <PageItem page={pageName} id="expat" name={"expat"} />
                <PageItem page={pageName} id="payroll" name={"payroll"} />
                <PageItem page={pageName} id="juridisch" name={"juridisch"} noButton noReadMore />
                <PageItem page={pageName} id="opleidingen" name={"opleidingen"} noButton noReadMore />
            </GenericPage>
        );
    }
}

export default translate(Diensten);
