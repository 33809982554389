import * as React from 'react'
import {translate} from 'react-multi-lang'
import Gallery from 'react-grid-gallery'

class Event extends React.PureComponent {
	render () {
		const { t,  name } = this.props;
		const data = require('../translations/en');
		const events = data.events;

		const renderImage = (name) => {
			//Make an object array of the $eventName which is passed through
			const eventArray = Object.values(events[name]);

			//Get the second element of the $name object array(which is the nested image object)
			//Set its values as an array
			return Object.values(eventArray[1])
		};

		const renderParagraphs = (name) => {
			//Make an object array of the $eventName which is passed through
			const eventArray = Object.values(events[name]);

			//Get the third element of the $name object array(which is the nested text object)
			//Set its values as an array
			const eventText = Object.values(eventArray[2]);

			const pars = [];
			// Loop over nested textObject array starting from i=2
			// i = 2 because it represent the 3rd index and start of(text_1) of the Text object array in the Text.json
			for (let i = 2; i < eventText.length; i++) {
				pars.push(
					<React.Fragment className="read-more-text">
					<p>{eventText[i]}</p>
					</React.Fragment>,
				)
			}
			return pars
		};

		return (
			<div className="event-wrapper">
				<div className="read-more-title-text">
					<h2>{t(`events.${name}.text.title`)}</h2>
				</div>


				<div className="event-read-more-text">
				{renderParagraphs(name)}
					<Gallery images={renderImage(name)}/>
				</div>
			</div>
		)
	}
}

export default translate(Event)
