import React, {Component} from "react";
import GenericPage from "../pages/GenericPage";
import PageItem from "../pages/PageItem";
import {translate} from "react-multi-lang";

class Employers extends Component {
    render() {
        const pageName = "opdrachtgever";

        return (
            <GenericPage page={pageName}>
                <PageItem page={pageName} id="vast_personeel" name={"vast_personeel"} />
                <PageItem page={pageName} id="tijdelijk_personeel" name={"tijdelijk_personeel"}/>
                <PageItem page={pageName} id="bechikbare_profielen" noButton name={"beschikbare_profielen"}  />

                {/*<Tabel id="bechikbare_profielen"/>*/}

            </GenericPage>
        );
    }
}

export default translate(Employers);
