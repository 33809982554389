import React, {Component} from "react";
import Fade from "react-reveal/Fade";

class NoRef extends Component {
  render() {
    return (
            <Fade>
              <div className="not-found-wrapper">
                  <img  alt="not-found" className="not-found-image" src="images/404/preview.jpg"/>
                <h2>
                  404... Page <code>{this.props.location.pathname}</code> was not
                  found.
                </h2>
              </div>
            </Fade>
    );
  }
}

export default NoRef;
