import React, {Component} from 'react'
import GenericPage from '../pages/GenericPage'
import {translate} from 'react-multi-lang'
import Testimonials from '../Testimonials/Testimonials'
import en from "../../images/testi_en.jpg";
import nl from "../../images/testi_nl.jpg";

class TestimonialPage extends Component {
    render() {
        const pageName = "testimonial";

        return (
            <GenericPage specialImage={{en:en , nl: nl}} page={pageName}>
                <Testimonials path="testimonial.leon"/>
                <Testimonials path="testimonial.arthur"/>
                <Testimonials path="testimonial.erik"/>
                <Testimonials path="testimonial.richard"/>
                <Testimonials path="testimonial.dilip"/>

            </GenericPage>
        );
    }
}

export default translate(TestimonialPage);
