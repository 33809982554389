import React from "react";
import GenericPage from "../pages/GenericPage";
import { translate } from "react-multi-lang";
import Team from '../Team'
import AOS from 'aos';
import WideText from "../pages/WideText";


AOS.init();

class About extends React.PureComponent {
	render() {
		const pageName = "about";

		return (
			<div style={{paddingTop:"10vh"}} id={this.props.id}>
				<GenericPage para page={pageName}>
				</GenericPage>
				<WideText/>
			</div>
		);
	}
}

export default translate(About);
