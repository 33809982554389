import React from "react";
import {translate} from "react-multi-lang";
import {generalPagesImagePath} from "../App";
import {NavLink} from "react-router-dom";

class ReadMore extends React.PureComponent {
    render() {
        const { page, name, paragraphs, t, noButton, customUrl } = this.props;
        return (
            <div className="read-more-page-item">
                <div className="read-more-title">
                    <img
                        className="read-more-title-image"
                        src={`${generalPagesImagePath}${page}/${name}.symbol.svg`}
                        alt={""}
                    />
                    <div className="read-more-title-text">
                        <h2>{t(`${page}.${name}.title`)}</h2>
                    </div>
                </div>
                <div className="read-more-text">
                    {paragraphs === undefined ? (
                        <React.Fragment>
                            <p>{t(`${page}.${name}.preview`)}</p>
                            <NavLink exact to={`/read_more/${page}/${name}`}>
                                <div className="read-more">
                                    {t("page_item.read_more")}
                                </div>
                            </NavLink>
                        </React.Fragment>
                    ) : (
                        this.renderParagraphs()
                    )}
                </div>
                {!noButton && (
                    <NavLink
                        exact
                        to={customUrl ? customUrl : `/contact/${page}/${name}`}
                    >
                        <div className="general-button">
                            <p>
                                {customUrl
                                    ? t(`${page}.${name}.button`)
                                    : t("page_item.contact")}
                            </p>
                        </div>
                    </NavLink>
                )}
            </div>
        );
    }

    renderParagraphs = () => {
        const breakWord = "AlineaBreak";
        const { t, page, name } = this.props;
        const pars = [];

        const Paragraphs = t(`${page}.${name}.text`);
        const Array = Paragraphs.split(breakWord);
        for(let i =0; i < Array.length; i++){
            pars.push(

                <React.Fragment>
                    <p>{Array[i]}</p>
                    <br/>
                </React.Fragment>
            )
        }
        return pars;
}
}

export default translate(ReadMore);
