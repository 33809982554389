import React, { Component } from "react";
import GenericPage from "../pages/GenericPage";
import PageItem from "../pages/PageItem";
import { translate } from "react-multi-lang";

class Partners extends Component {
    render() {
        const pageName = "partners";

        return (
            <GenericPage page={pageName}>
                <PageItem
                    page={pageName}
                    name={"waarom"}
                    imageHeight={this.props.imageHeight}
                    id="waarom_esp"
                />
                {/*<PageItem*/}
                {/*    page={pageName}*/}
                {/*    name={"werkwijze"}*/}
                {/*    imageHeight={this.props.imageHeight}*/}
                {/*    noReadMore*/}
                {/*    id="werkwijze"*/}
                {/*/>*/}
            </GenericPage>
        );
    }
}

export default translate(Partners);
