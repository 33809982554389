import React from "react";
import { translate } from "react-multi-lang";
import { generalPagesImagePath } from "../../../App";
import ContactForm from "./ContactForm";

class ContactPage extends React.PureComponent {
    render() {
        const { page, name, t } = this.props;
        return (
            <div className="contact-form-page">
                <div className="contact-title">
                    {/* TODO: Add background image */}
                    <div className="title-subtext">
                        <p>{t(`${page}.${name}.preview`)}</p>
                    </div>
                    <div className="title-wrapper">
                        <div className="title">
                            <img
                                className="title-image"
                                src={
                                    `${generalPagesImagePath}${page}/${name}.svg`
                                }
                                alt={""}
                            />
                            <div className="title-text">
                                <p>{t(`${page}.${name}.title`)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* TODO: Add right page check here */}
                <ContactForm page={page} name={name} hasMoreFields={page !== "employers"} />
            </div>
        );
    }
}

export default translate(ContactPage);
