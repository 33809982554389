import React from 'react'
import { translate } from 'react-multi-lang'



function Testimonials (props) {

	const renderParagraphs = (data) => {
		const breakWord = "AlineaBreak";
		const { t, } = props;
		const pars = [];

		const Paragraphs = t(data);
		const Array = Paragraphs.split(breakWord);
		for(let i = 0; i < Array.length; i++){
			pars.push(
				<React.Fragment>
					<p>{Array[i]}</p>
					<br/>
				</React.Fragment>
			)
		}
		return pars;
	};

	return (
		<div style={{marginBottom:"30px", width:"90%"}} className="subtitle">
			<p style={{width:"100%", maxWidth:"500px", margin:"auto", textAlign:"justify"}}>{renderParagraphs(`${props.path}.text`)}</p>
			<p style={{width:"100%", maxWidth:"500px", margin:"auto", fontStyle:"italic"}}>{renderParagraphs(`${props.path}.author`)}</p>
		</div>
	)
}

export default translate(Testimonials)
