import React from 'react'
import ReadmoreButton from './ReadmoreButton'
import { translate } from 'react-multi-lang'
import AOS from 'aos'
import { Element } from 'react-scroll'

AOS.init();

const eventPreview = [
	{
		image: '../images/workCards/briefcase-search.svg',
		key: 1,
		name: 'freelancer',
		url: 'freelance',
	},
	{
		image: '../images/workCards/person-icon.svg',
		key: 2,
		name: 'diensten',
		url: 'services',

	},

	{
		image: '../images/workCards/briefcase.svg',
		key: 3,
		name: 'opdrachtgever',
		url: 'employers',

	},
];

const mq = window.matchMedia('(min-width: 1200px)');

class WorkCards extends React.PureComponent {

	constructor (props) {
		super(props);
		this.height = React.createRef();
		this.state = {
			pc: mq.matches,
		}
	}

	mobileSlide = (index) => {
		switch (index % 2) {
			case 0:
				return 'fade-right';
			case 1 :
				return 'fade-left';
			default:
				return 'fade-left'
		}
	};

	pcSlide = () => {
		return 'fade-up'
	};

	componentDidMount () {
		window.addEventListener('resize', this.resize, false)
	}

	componentWillUnmount () {
		window.removeEventListener('resize', this.resize, false)
	}

	resize = () => {
		if (mq.matches !== this.state.pc) {
			this.setState({ pc: mq.matches })
		}
	};

	renderPc = () => {
		const { t } = this.props;

		return <div className="work-wrapper" id={this.props.id} ref={this.height}>
			<Element name="scroll-to-element"> </Element>
			{/*// Reference to scroll function in Landing page*/}
			<div data-aos="fade-in"
				 data-aos-offset="20"
				 data-aos-delay="50"
				 data-aos-duration="500"
				 data-aos-easing="ease-in-out"
				 data-aos-mirror="false"
				 data-aos-once="true"
				 data-aos-anchor-placement="bottom" className="title">
				<h2>{t(`home.workcards.title`)}</h2>
			</div>
			<div className="card-wrapper">
				{eventPreview.map((data, index) => {
					return <div data-aos="fade-up"
								data-aos-offset="200"
								data-aos-delay="50"
								data-aos-duration="1000"
								data-aos-easing="ease-in-out"
								data-aos-mirror="false"
								data-aos-once="true"
								data-aos-anchor-placement="top"
								className="work-card" key={data.key}>
						<div className="work-box">
							<img src={data.image} alt={data.name}/>
						</div>
						<h3>{t(`home.workcards.${data.name}.title`)}</h3>
						<div className="work-text">
							<p>{t(`home.workcards.${data.name}.text`)}</p>
						</div>
						<ReadmoreButton title="READ MORE!" pagePath={data.url}/>
					</div>
				})}
			</div>
		</div>
	};

	render () {

		return (
			<div>
				{this.renderPc()}
			</div>
		)
	}
}

export default translate(WorkCards)
