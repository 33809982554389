import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { translate } from 'react-multi-lang'
import './styles/App.scss'

import Language from './Language'

import Home from './components/collections/Home'
import Services from './components/collections/Diensten'

import NoRef from './components/404'
import Header from './components/Header'

import { Cookies, withCookies } from 'react-cookie'
import { instanceOf } from 'prop-types'
import Employers from './components/collections/Employers'
import Partners from './components/collections/Partners'
import Freelance from './components/collections/Freelance'
import TechCommunity from './components/collections/TestimonialPage'
import About from './components/collections/About'
import Event from './components/Event'
import ContactPage from './components/pages/contact/ContactPage'
import { contact, read_more } from './restrictions'

import Footer from './components/Footer'
import ReadMore from './components/ReadMore'

const NoMatch = ({ location }) => (
	<div>
		<NoRef location={location}/>
	</div>
)

class App extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired,
	}

	constructor (props) {
		super(props)
		Language.initialize()
	}

	render () {
		return (
			<div className="App-container">
				<link/>
				<Header/>
				<div id="body">
					<Switch>
						<Route path="/" component={Home} exact/>
						<Route path="/freelance" component={Freelance} exact/>
						<Route path="/services" component={Services} exact/>
						<Route path="/employers" component={Employers} exact/>
						<Route path="/partners" component={Partners} exact/>
						<Route path="/testimonials" component={TechCommunity} exact/>
						<Route path="/about" component={About} exact/>

						<Route path="/events/:name" exact component={({ match }) => {
							return <Event page={match.params.page} name={match.params.name}/>
							}
						}
						/>

						<Route
							path="/contact/:page/:name"
							component={({ match }) =>
								contact.indexOf(
									`${match.params.page}/${match.params.name}`,
								) !== -1 ? (
									<ContactPage
										page={match.params.page}
										name={match.params.name}
									/>
								) : (
									// TODO: navigate to 404 page
									<div/>
								)
							}
							exact
						/>
						<Route
							path="/read_more/:page/:name"
							component={({ match }) => {
								const keys = Object.keys(read_more)
								const key = `${match.params.page}/${
									match.params.name
									}`
								return keys.indexOf(key) !== -1 ? (
									<div style={{
										width: '100%', display: 'flex', justifyContent: 'center',
										textAlign: 'left',
									}}>
										<ReadMore
											page={match.params.page}
											name={match.params.name}
											paragraphs={read_more[key]}
											noButton={contact.indexOf(
												`${match.params.page}/${match.params.name}`,
											) === -1}
										/>
									</div>
								) : (
									// TODO: navigate to 404 page
									<div/>
								)
							}}
							exact
						/>
						<Route component={NoMatch}/>
					</Switch>
					<Footer/>
				</div>
			</div>
		)
	}
}

export default withCookies(translate(App))
export const generalPagesImagePath = '/images/pages/'
