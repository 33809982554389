import * as React from 'react'
import {translate} from "react-multi-lang";
import {FaEnvelope, FaLinkedin, FaPhone} from 'react-icons/fa'

class Team extends React.PureComponent {


	renderParagraphs = (data) => {
		const breakWord = "AlineaBreak";
		const { t, } = this.props;
		const pars = [];

		const Paragraphs = t(`about.team.${data.name}.text`);
		const Array = Paragraphs.split(breakWord);
		for(let i =0; i < Array.length; i++){
			pars.push(

				<React.Fragment>
					<p>{Array[i]}</p>
					<br/>
				</React.Fragment>
			)
		}
		return pars;
	};


	render () {
		const Team = [
			{
				image: '../images/about/ceo.jpg',
				key: 1,
				name: 'mark_wisse',
				id: 'mark_Wisse',
			},
			{
				image: '../images/about/ceo.jpg',
				key: 2,
				name: 'ravi_badal',
				id: 'ravi_Badal',
			},

			{
				image: '../images/about/ceo.jpg',
				key: 3,
				name: 'henk_ter_braack',
				id: 'henk_ter_Braack',

			},
		];

		const {t} = this.props;

		return (
			<div id={this.props.id} className="team-center">
				<h2 className="team-center">Team</h2>
				<p className="team-text">{t("about.team.text")}</p>
				<div className="team-wrapper">

						{Team.map((data, index) => {
							return <div
								data-aos="fade-up"
								data-aos-offset="200"
								data-aos-delay="50"
								data-aos-duration="1000"
								data-aos-easing="ease-in-out"
								data-aos-mirror="false"
								data-aos-once="true"
								data-aos-anchor-placement="top" className="team-card" key={data.id}>
								<div className="team-image" style={{backgroundImage:"url(../images/pages/about/silhouette.png)"}} >
								</div>
								<div className="team-image-info team-center">
									<p className="team-image-name">{t(`about.team.${data.name}.name`)}</p>
									<p>{t(`about.team.${data.name}.title`)}</p>
									<span> </span>
								</div>
								<div className="team-image-text team-center">
									<p>
										{this.renderParagraphs(data)}
									</p>
								</div>
								<div className="team-social-media-wrapper">
									<div className="team-social-media">
										<a href={`${t(`about.team.${data.name}.linked_in`)}`} target="_blank" rel="noopener noreferrer"><FaLinkedin/></a>
										<a href={`mailto:${t(`about.team.${data.name}.email`)}`} target="_blank" rel="noopener noreferrer"><FaEnvelope/></a>
										<a href={`${t(`about.team.${data.name}.mobile`)}`} target="_blank" rel="noopener noreferrer"><FaPhone/></a>
									</div>
								</div>
							</div>
						})}
				</div>
			</div>
		)
	}
}

export default translate(Team);
