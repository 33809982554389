import React from "react";
import {generalPagesImagePath} from "../../App";
import Language from "../../Language";
import {getLanguage, translate} from 'react-multi-lang'
class GenericPage extends React.PureComponent {

    static selectPreferredLanguage(language) {
        Language.selectPreferredLanguage(language);
        window.location.reload()
    }

    constructor(props){
        super(props);
        const {page} = this.props;
        this.state={
            landingImage:`${generalPagesImagePath}${page}/title_image.jpeg`,
        }
    }
    componentDidMount () {
         // const highRes = new Image();
        // highRes.src = `${generalPagesImagePath}${page}/title_image-high-res.png`;
        // highRes.onload = () => {this.setState({landingImage:highRes.src}, function () {
        // });}
    }

    renderParagraphs = () => {
        const breakWord = "AlineaBreak";
        const { t, page } = this.props;
        const pars = [];
        const Paragraphs = t(`${page}.text`);
        const Array = Paragraphs.split(breakWord);
        for(let i =0; i < Array.length; i++){
            pars.push(
                <React.Fragment>
                    <p>{Array[i]}</p>
                    <br/>
                </React.Fragment>
            )
        }

        return pars;

    };
render() {
        const {children, t, page, specialImage}= this.props;
        return (
            <div className="page">
                {console.log(getLanguage().toString())}
                <div className="title-view">
                    <div className="title-image-wrapper">
                        <img
                            className="title-image-1 background-image"
                            src={`${generalPagesImagePath}/decoration/Group 208.svg`}
                            alt={""}
                        />
                        <img
                            className="title-image"
                            src={ specialImage ? (getLanguage().toString() === "en" ? specialImage.en : specialImage.nl) : `${generalPagesImagePath}${page}/title_image.jpg`}
                            alt={""}
                        />
                        <img
                            className="title-image-2 foreground-image"
                            src={`${generalPagesImagePath}/decoration/Path 35.svg`}
                            alt={""}
                        />
                        <img
                            className="title-image-3 foreground-image"
                            src={`${generalPagesImagePath}/decoration/Rectangle 51.svg`}
                            alt={""}
                        />
                    </div>
                    <div className="title-wrapper">
                        <div className="title">
                            <div className="title-text">{t(`${page}.title`)}</div>
                            <img
                                className="title-symbol"
                                src={`
                  ${generalPagesImagePath}${page}/title_symbol.svg`}
                                alt={""}
                            />
                        </div>
                        <div className="subtitle">{this.renderParagraphs()}</div>
                    </div>
                </div>
                <div className="items-wrapper">
                    <img
                        className="side-decoration decoration-left"
                        src={`${generalPagesImagePath}/decoration/Rectangle 10.svg`}
                        alt={""}
                    />
                    <img
                        className="side-decoration decoration-right"
                        src={`${generalPagesImagePath}/decoration/Group 210.svg`}
                        alt={""}
                    />
                    <div className="items">{children}</div>
                </div>
            </div>
        );
    }
}

export default translate(GenericPage);
