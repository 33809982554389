import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import unregisterServiceWorker from "./registerServiceWorker";
import Analytics from "react-router-ga";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";

ReactDOM.render(
  <Router>
    <Analytics id="UA-90768292-3">
      <ScrollToTop>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </ScrollToTop>
    </Analytics>
  </Router>,
  document.getElementById("root")
);

unregisterServiceWorker();
