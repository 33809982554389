import React from "react";
import { NavLink } from "react-router-dom";
import { translate, t } from 'react-multi-lang'

class GridItem extends React.PureComponent {
    render() {
        const {image, url , text, noIcon} = this.props;

        if (noIcon) {
            return(
                <div className="grid-item">
                    <div className="grid-text">
                        <NavLink to={url}>{t(`footer.${text}`)}</NavLink>
                    </div>
                </div>
            )
        }
        return (
            <div className="grid-item">
                <div id={this.props.id} className={`grid-image`}>
                    {image}
                </div>
                <div className="grid-text">
                    <a href={url} rel="noopener noreferrer" target="_blank">{t(`footer.information.${text}`)}</a>
                </div>
            </div>
        );
    }
}

export default translate(GridItem);
