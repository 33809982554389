import React from "react";
import {NavLink} from "react-router-dom";

class Button extends React.PureComponent {
    render() {
        const {customButton, } = this.props;

        if (customButton) {
            return <a href={this.props.url} target="_blank" rel="noopener noreferrer">
                <div className="general-button" style={{width: this.props.width}}>
                    <p>{this.props.title}</p>
                </div>
            </a>
        }

        else{
            return (
                <NavLink exact to={this.props.pagePath}>
                        <div className="general-button" style={{width: this.props.width}}>
                            <p>{this.props.title}</p>
                        </div>
                </NavLink>
            );
        }
    }
}

export default Button;
