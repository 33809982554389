import React from "react";
import { NavLink } from "react-router-dom";

class ReadmoreButton extends React.PureComponent {
    render() {
        return (
            <NavLink exact to={this.props.pagePath}>
                <div className="read-more-button">
                    <p>{this.props.title}</p>
                </div>
            </NavLink>
        );
    }
}

export default ReadmoreButton;
