import React from "react";
import {translate} from "react-multi-lang";
import {Form} from "semantic-ui-react";
import InputField from "./InputField";


class ContactForm extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			formData: {},
			success: false
		};
	}

	render() {
		const {t, hasMoreFields} = this.props;
		return (
			<div className="contact-form">
				<p className="title">{t("contact_form.title")}</p>
				<Form id="contact-form" method="POST" enctype="multipart/form-data"
				      action={process.env.REACT_APP_API_BASE_URL}>
					<InputField
						label={t(`contact_form.first_name`)}
						handleChange={this._handleChange}
						isMandatory={true}
					/>
					<InputField
						label={t(`contact_form.middle_name`)}
						handleChange={this._handleChange}
					/>
					<InputField
						label={t(`contact_form.last_name`)}
						handleChange={this._handleChange}
						isMandatory={true}
					/>
					<InputField
						label={t(`contact_form.email`)}
						handleChange={this._handleChange}
						isMandatory={true}
					/>
					<InputField
						label={t(`contact_form.mobile`)}
						handleChange={this._handleChange}
					/>
					<InputField
						label={t(`contact_form.address`)}
						handleChange={this._handleChange}
					/>
					<InputField
						label={t(`contact_form.postal_code`)}
						handleChange={this._handleChange}
					/>
					<InputField
						label={t(`contact_form.place`)}
						handleChange={this._handleChange}
					/>
					{hasMoreFields && (
						<React.Fragment>
							{/* TODO: Add date picker here */}
							{/* TODO: Add gender here */}
							<InputField
								label={t(`contact_form.linkedIn`)}
								handleChange={this._handleChange}
							/>
							{/* TODO: Add cv here */}
						</React.Fragment>
					)}
					<InputField
						label={`${t(`contact_form.message`)}`}
						handleChange={this._handleChange}
						isBigField={true}
					/>
					<label>CV upload (word)</label><input
					                                      accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
					                                      type="file" name="fileName"></input>
					{/* TODO: Add voorwaarden en bepalingen checkbox */}
					{/* TODO: Add privacyverklaring checkbox */}
					{/*eslint-disable-next-line*/}
					<a onClick={() => this.handleSubmit()} className="general-button submit-button">
						{t("contact_form.submit")}
					</a>
					{this.state.success ? <div style={{color: "green", textAlign: "center"}}>Submitted successfully</div> : null}
				</Form>
			</div>
		);
	}

	_handleChange = (name, value) => {
		this.setState({formData: Object.assign({}, this.state.formData, {[name]: value})}, () => console.log(this.state.formData))
	};
	handleSubmit = () => {
		let self = this;

		let formData = new FormData();
		formData.append("subject", "ESP contact form: " +this.props.page + "/" + this.props.name);
		formData.append("recipient", "ravi@espconsultancy.nl");
		let body = "Form contents: \n";
		//
		for (const key in this.state.formData) {
			body = body.concat(`[${key} = ${this.state.formData[key]}] \n`);
			this._handleChange(key, "");
		}

		console.log(body);
		console.log(JSON.stringify(body));

		formData.append("body", body);

		const cv = document.querySelector('input[type="file"]').files[0];
		formData.append("file", cv);

		console.log(formData);
		fetch(process.env.REACT_APP_API_BASE_URL, {
			method: 'POST',
			body: formData,
		}).then(function (response) {
			if (!response.ok) {
				response.json().then(function (object) {
					console.log(object);
					console.log(object.propertyErrors);
					self.setState({formError: object.propertyErrors})
				});
				throw new Error(response.statusText);
			}
			self.setState({success: true});
			document.getElementById("contact-form").reset();
			console.log(response)
		}).catch(error => console.log(error));
	};
}

export default translate(ContactForm);
