import * as React from 'react'
import Landing from '../../components/Landing'
import Banner from '../../components/Banner'
import WorkCards from '../WorkCards'
import Section from '../Section'
import About from './About'

class Home extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			scroll: 0,
		}
	}

	render() {

		return (
			<div>
				<Landing/>
				<About id="over_esp"/>
				<Section
					title="home.esp.slogan"
					titleTwo="home.esp.slogan_2"
					background="/images/sections/colored-square.svg"
					button={false}
					image="/images/sections/h3.jpg"
					imageMobile="/images/sections/h3.jpg"
				/>
				<WorkCards id="werk"/>
				<Section
					title="home.phares.sub_title"
					background="/images/sections/colored-square.svg"
					button={true}
					logo="/images/sections/PharesLogo.png"
					image="/images/sections/h4.jpg"
					imageMobile="/images/sections/h4.jpg"
					text="home.phares.text"
					alternative="Phares"
					logolAlt="Phares-logo"
					url="http://www.phares.nl/"
					id="phares"
				/>
				<Banner id="clienten"/>
			</div>
		)
	}
}

export default Home
