export const contact = [
	"events/lustrum",
	'freelance/leveranciers',
	'freelance/freelancers',
	'freelance/vaste_banen',
	'freelance/opdrachten',
	'freelance/inschrijven',
	'freelance/freelancer_worden',
	'diensten/freelance',
	'diensten/concept',
	'diensten/expat',
	'diensten/payroll',
	'opdrachtgever/vast_personeel',
	'opdrachtgever/tijdelijk_personeel',
	'opdrachtgever/profiles',
	'partners/waarom',
	"partners/werkwijze",
	"tech_community/communities",
	"tech_community/automated",
	"tech_community/java",
	"tech_community/bi",
	"tech_community/infra",
	"general_contact/info"
];
export const read_more = {
	"events/lustrum":0,
	'freelance/leveranciers': 0,
	'freelance/freelancers': 2,
	'freelance/vaste_banen': 3,
	'freelance/opdrachten': 3,
	'freelance/inschrijven': 3,
	'freelance/tips': 6,
	'freelance/werkwijze': 2,
	'freelance/freelancer_worden': 2,
	'diensten/freelance': 1,
	'diensten/concept': 3,
	'diensten/expat': 3,
	'diensten/payroll': 2,
	'diensten/juridisch': 1,
	'diensten/opleidingen': 1,
	'opdrachtgever/vast_personeel': 2,
	'opdrachtgever/tijdelijk_personeel': 2,
	'opdrachtgever/profiles': 1,
	'opdrachtgever/beschikbare_profielen':1,
	'partners/waarom': 5,
	"partners/werkwijze": 5,
	"tech_community/communities": 1,
	"tech_community/events": 2,
	"tech_community/automated":1,
	"tech_community/java":1,
	"tech_community/bi":1,
	"tech_community/infra":1,
};

