import React from "react";
import {translate} from "react-multi-lang";
import Button from "./Button";

class Section extends React.PureComponent {
    render() {
        const {t, title, text, image, imageMobile, logo, background, button, titleTwo, alternative, logoAlternative, id, url} = this.props;
        return (
            <div className="section-wrapper" id={id}>
                <div className="section-body">
                    <img src={background} className="section-background" alt={"colored-background"}/>
                    <div className="section-content">
                        <div className="section-text">
                            <h2 className="section-title pc-section">{title === undefined ? null : t(`${title}`)}</h2>
                            <h2 className="section-title pc-section">{titleTwo === undefined ? null : t(`${titleTwo}`)}</h2>
                            <img src={logo} className="section-logo" alt={logoAlternative}/>
                            <h2 className="section-title mobile-section">{title === undefined ? null : t(`${title}`)}</h2>
                            <h2 className="section-title mobile-section">{titleTwo === undefined ? null : t(`${titleTwo}`)}</h2>
                            <p>{text === undefined ? null : t(`${text}`)}</p>
                            {button ? <Button title={t("button.see")} width="150px" customButton
                                              className="bottom" url={url}/>
                                : null}
                        </div>
                        <div className="shapes-wrapper">
                            <img src={image} className="section-image" alt={t(`${alternative}`)}/>
                            <img src={imageMobile} className="section-image-mobile" alt={t(`${alternative}`)}/>
                            <img src="/images/sections/half-square.svg" alt="half-square" className="half-square"/>
                            <img src="/images/sections/square.svg" className="square" alt="half-square"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate(Section);
